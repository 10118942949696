// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import '../stylesheets/application'
import 'bootstrap/dist/js/bootstrap'
import "@fortawesome/fontawesome-free/css/all"
import "../../assets/fontawesome/all"
import "chartkick/chart.js"
import "chartkick/highcharts"
import ahoy from "ahoy.js"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

window.ahoy = ahoy;

document.addEventListener("turbolinks:load", function () {
  if (window.posthog) {
    if (window.debugPosthog) { console.log("Capturing Turbolinks Posthog Pageview") }
    window.posthog.capture('$pageview');
  }
  // if (window.freshpaint) {
  //   if (window.debugFreshpaint) { console.log("Capturing Turbolinks Freshpaint Pageview") }
  //   window.freshpaint.page();
  // }
});

document.addEventListener("turbolinks:before-cache", function () {
  window.freshpaint.eventsTracked = false
  window.posthog.eventsTracked = false
  window.ahoy.eventsTracked = false
})

